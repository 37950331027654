const clients = ['frichti', 'pepette', 'ouihelp', 'archipels', 'ensol'];

const ClientLogos = () => {
  return (
    <div className="clients d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-between flex-wrap">
      {clients.map((client) => (
        <img
          className="client-logo"
          key={client}
          src={`/media-remix/clients/${client}.png`}
          alt={`${client} logo`}
        />
      ))}
    </div>
  );
};

export default ClientLogos;
